import clsx from 'clsx';
import LogRocket from 'logrocket';
import { GetStaticPropsContext } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AccountLayout from '@/components/account/AccountLayout';
import accountStyles from '@/components/account/accountStyles.module.scss';
import ResetPasswordToast from '@/components/account/ResetPasswordToast';
import Button from '@/components/global/Button';
import Footer from '@/components/global/Footer';
import Header from '@/components/global/Header';
import InputField from '@/components/global/InputField';
import { Layout } from '@/components/Layout';
import { useCheckout } from '@/components/providers/CheckoutProvider';
import { BaseSeo } from '@/components/seo/BaseSeo';
import { messages } from '@/components/translations';
import { useSaleorAuthContext } from '@/lib/auth';
import { LOGROCKET_ID, VERSION } from '@/lib/constants';
import { usePaths } from '@/lib/paths';
import { getStoryBlokProps } from '@/lib/storyblok/storyblokGetStaticProps';
import { getStoryBlokNavLinks } from '@/lib/storyblok/storyblokNavLinks';
import { inputFieldValidation } from '@/lib/util';

import styles from './index.module.scss';

export type OptionalQuery = {
  next?: string;
};

export interface LoginFormData {
  email: string;
  password: string;
}

const LoginPage = ({ story }) => {
  const router = useRouter();
  const paths = usePaths();
  const t = useIntl();

  const { signIn } = useSaleorAuthContext();
  const { checkout } = useCheckout();
  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm },
    setError: setErrorForm,
    getValues
  } = useForm<LoginFormData>({});

  const redirectURL = router.query.next?.toString() || paths.account.$url();

  const handleLogin = handleSubmitForm(async (formData: LoginFormData) => {
    const { data } = await signIn({
      email: formData.email,
      password: formData.password,
      checkout
    });

    if (data?.tokenCreate?.errors[0]) {
      setErrorForm('email', { message: 'Invalid credentials' });
    } else {
      if (LOGROCKET_ID) LogRocket.identify(formData.email);
      router.push(redirectURL);
    }
  });

  const globalLinks = getStoryBlokNavLinks(story);

  return (
    <>
      <BaseSeo title="Login — Colophon Foundry" />
      <Header navigation={globalLinks} blok={story.content} />
      <ResetPasswordToast />
      <main id="main" className={styles.LoginPage}>
        <AccountLayout>
          <>
            <h1 className={clsx(styles.LoginPageHeading, 'u-h5')}>
              Log In or sign up to access your Colophon account.
            </h1>

            <form className={accountStyles.AccountForm} onSubmit={handleLogin} noValidate>
              <div className={accountStyles.FormFieldContainer}>
                <InputField
                  type="email"
                  label="email ⁕"
                  placeholder="mail@website.com"
                  {...registerForm('email', {
                    required: 'ⓘ Please enter an email address',
                    ...inputFieldValidation.emailFormat
                  })}
                  hasLabel
                  error={!!errorsForm.email && errorsForm.email?.message}
                />
              </div>
              <div className={accountStyles.FormFieldContainer}>
                <InputField
                  type="password"
                  label="password ⁕"
                  placeholder="⁕⁕⁕⁕⁕⁕⁕"
                  {...registerForm('password', {
                    required: 'ⓘ Please enter a valid password'
                  })}
                  hasLabel
                  error={!!errorsForm.password && errorsForm.password?.message}
                />
              </div>
              <div className={accountStyles.FormFieldButtons}>
                <Button size="l">{t.formatMessage(messages.logIn)}</Button>
                <Button size="l" variant="secondary" href={paths.account.register.$url()}>
                  {t.formatMessage(messages.createAccount)}
                </Button>
              </div>
              <Link href="/account/forgot-password">
                <a className={styles.RemindPassword}>
                  {t.formatMessage(messages.loginRemindPasswordButtonLabel)}
                </a>
              </Link>
            </form>
          </>
        </AccountLayout>
      </main>
      <Footer theme="grey" navigation={globalLinks.footerLinks} />
    </>
  );
};

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const pageSlug = 'home';
  const preview = context.preview || false;
  const resolveRelations = 'page.globalHeader,page.globalFooter';
  const story = await getStoryBlokProps({ pageSlug, context, VERSION, resolveRelations });

  return {
    props: {
      preview,
      story: story || null,
      resolve_relations: resolveRelations
    },
    revalidate: 60 * 60 // value in seconds, how often ISR will trigger on the server
  };
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default LoginPage;
