import { Column, Grid } from '@/components/global/Grid';

import styles from './accountStyles.module.scss';

const AccountLayout = ({ children, short = false, centered = false }) => {
  return (
    <div className={short ? styles.pageContainerShort : styles.pageContainer}>
      <Grid rowAlign={centered ? 'center' : 'flex-start'}>
        <Column sm={12} md={4} lg={1}></Column>
        <Column sm={12} md={12} lg={5}>
          {children}
        </Column>
        <Column sm={12} md={4} lg={1}></Column>
        <Column sm={12} md={4} lg={5}>
          <div className={styles.ImageContainer}>
            <img src="/img/login.svg" alt="" width="1028" height="662" />
          </div>
        </Column>
      </Grid>
    </div>
  );
};

export default AccountLayout;
