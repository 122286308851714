import { useRouter } from 'next/router';
import React from 'react';

import Toast from '@/components/global/Toast';
import { usePaths } from '@/lib/paths';

const ResetPasswordToast = () => {
  const router = useRouter();
  const paths = usePaths();

  const clearUrlParams = () =>
    router.replace(paths.account.login.$url(), undefined, { shallow: true });

  if (router.query?.reset_password === 'success') {
    return (
      <Toast
        message="New password successfully created! Use your new password to log in into your account."
        autoClose
        autoCloseTime={3000}
        onClose={clearUrlParams}
      />
    );
  }

  return;
};

export default ResetPasswordToast;
